
import { mapStores } from "pinia"

import { AnalyticsEventPageId } from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"

import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import SitePlannerViewer from "@/components/sitePlanner/SitePlannerViewer"

export default {
  name: "SitePlannerProject",
  meta: {
    pageId: AnalyticsEventPageId.SitePlanner,
  },
  components: { EvercamLoadingAnimation, SitePlannerViewer },
  data: () => ({
    sitePlanId: null,
    isAddingNewPlan: false,
  }),
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
  },

  async mounted() {
    const project_exid = this.$route.params.project_exid

    try {
      // Fetch site plan meta data
      await this.fetchSitePlanMetaDataById(project_exid)
    } catch (error) {
      this.$notifications.error({
        text: "Error fetching site plan metadata.",
        error: error,
      })
    } finally {
      if (!this.sitePlanId) {
        // Use the selected project id as site plan id
        this.isAddingNewPlan = true
        this.sitePlanId = project_exid
      }
    }
  },
  methods: {
    async fetchSitePlanMetaDataById(sitePlanId) {
      const response = await fetch(
        this.$config.public.firebaseDbLink +
          `data/planner-list/${sitePlanId}.json`
      )
      const sitePlan = await response.json()
      this.sitePlanId = sitePlan?.projectId
    },
  },
}
